import { useEffect, useMemo, useRef, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteLivro, getLivros } from "../../../services/biblioteca";
import Spinner from "../../../components/spinner";
import { getUnidades } from "../../../services/unidades";
import { toast } from "react-toastify";
import urlsGestao from "../../gestao/urlsGestao";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { getAlunos } from "../../../services/alunos";

const EmprestimosPage = (gestao) => {
  const unidadeID = localStorage.getItem("unidadeID");

  const navigate = useNavigate();
  const [newData, setNewData] = useState("");
  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [warningControl, setWarningControl] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [unidadesModal, setUnidadesModal] = useState([]);
  const [alunosModal, setAlunosModal] = useState([]);
  const [livrosModal, setLivrosModal] = useState([]);
  const [show, setShow] = useState(false);
  const [showModalEmprestimo, setShowModalEmprestimo] = useState(false);
  const [livro, setLivro] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [unidadeSelected, setUnidadeSelected] = useState(
    gestao.gestao ? 0 : unidadeID
  );
  const [unidadeModalSelected, setUnidadeModalSelected] = useState(
    gestao.gestao ? 0 : unidadeID
  );
  const [livroModalSelected, setLivroModalSelected] = useState();

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => {
    setUnidadeModalSelected(0);
    setIsOpen(false);
  };

  // table ref
  const tableRef = useRef(null);

  // table download
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "livros",
    sheet: "Livros",
  });

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "TODOS" }];
        let dadosModal = [{ value: 0, label: "SELECIONE UMA UNIDADE" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
          dadosModal.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
        setUnidadesModal(dadosModal);
      },
    }
  );

  const { isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery(
    "getAlunos",
    () => getAlunos(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "TODOS" }];
        let dadosModal = [{ value: 0, label: "SELECIONE UM ALUNO" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
          dadosModal.push({ value: item.id, label: item.nome });
        });

        setAlunosModal(dadosModal);
      },
    }
  );

  const { isLoading: loadingLivros, refetch: refetchLivros } = useQuery(
    "getLivros",
    () => getLivros(skip, limit),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        let dadosModal = [{ value: 0, label: "SELECIONE UM LIVRO" }];
        data.forEach((item) => {
          dadosModal.push({ value: item.id, label: item.titulo });
        });

        setLivrosModal(dadosModal);
      },
    }
  );

  const editarUrl = urlsGestao.livroEditar;
  const adicionarUrl = urlsGestao.livroAdicionar;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const { refetch, isLoading } = useQuery("getLivros", () => getLivros(skip, limit), {
    onSuccess: (data) => {
      let dadosModal = [{ value: 0, label: data.titulo }];
      setNewData(data);
    },
    enabled: true,
    retry: false,
  });

  const deletarLivro = async (id) => {
    try {
      await deleteLivro(id);
      toast.success("Livro deletado com sucesso.");
      refetch();
    } catch (err) {
      toast.error("Falha na exclusão do livro. Tente novamente mais tarde!");
    }
  };

  useEffect(() => {
    refetch();
  }, [
    search,
    limit,
    skip,
    // newData,
    // unidadeSelected,
    // unidadeModalSelected,
  ]);

  const columns = useMemo(
    () => [
      { Header: "Livro", accessor: "livro" },
      { Header: "Aluno", accessor: "aluno" },
      { Header: "Tipo de Movimentação", accessor: "movimentacao" },
      { Header: "Data do Empréstimo", accessor: "dataEmprestimo" },
      {
        Header: "Data Prevista de Devolução",
        accessor: "dataPrevistaDevolucao",
      },
      { Header: "Data da Devolução", accessor: "dataDevolucao" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => {
                setShow(!show);
                setLivro(row.original);
              }}
              className="btn btn-sm btn-success"
            >
              <i className="material-icons-outlined">arrow_forward</i> devolver
            </button>
          </>
        ),
      },
    ],
    [navigate, editarUrl, gestao.gestao, show]
  );

  const handleSearchButton = () => {
    if (search.length >= 3) {
      setSearch(search);
      refetch();
    }
  };

  const handleChange = (selectedOption) => {
    setUnidadeModalSelected(selectedOption.value);
  };

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Empréstimos"}
        itens={[BasePath, "Biblioteca", "Lista Empréstimos"]}
        actions={{ text: "Registrar Empréstimo" }}
        hasExcelButton
        onClickDownload={onDownload}
      />

      {/* <button onClick={() => setShowModalEmprestimo(true)}>
        TESTE
      </button> */}

      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Devolver Livro - {livro.titulo}</Modal.Title>
        </Modal.Header>
        <form id="form">
          <Modal.Body
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="titulo" className="form-label">
                  LIVRO
                </label>
                <input
                  className="form-control"
                  id="titulo"
                  value={livro.titulo}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="alunoId" className="form-label">
                  ALUNO
                </label>
                <input className="form-control" id="aluno" readOnly />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="dataDevolucao" className="form-label">
                  DATA DEVOLUÇÃO
                </label>
                <input
                  className="form-control"
                  id="dataDevolucao"
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="horaDevolucao" className="form-label">
                  HORA DEVOLUÇÃO
                </label>
                <input
                  className="form-control"
                  id="horaDevolucao"
                  type="time"
                  required
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Fechar
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={error ? true : false}
            >
              Devolver
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal centered show={showModalEmprestimo} onHide={() => setShowModalEmprestimo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Emprestar Livro</Modal.Title>
        </Modal.Header>
        <form id="form">
          <Modal.Body
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="unidadeId" className="form-label">
                  LIVROS
                </label>
                <Select
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="aria-live-color"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={livrosModal}
                  // isDisabled={gestao.gestao ? false : true}
                  value={livrosModal.find((x) => x.value === livroModalSelected)}
                  placeholder=""
                  onChange={async (e) => {
                    setLivroModalSelected(e.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="alunoId" className="form-label">
                  ALUNO
                </label>
                <input className="form-control" id="aluno" />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="dataDevolucao" className="form-label">
                  DATA EMPRÉSTIMO
                </label>
                <input
                  className="form-control"
                  id="dataDevolucao"
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group" style={{ width: "100%" }}>
                <label htmlFor="horaDevolucao" className="form-label">
                  HORA EMPRÉSTIMO
                </label>
                <input
                  className="form-control"
                  id="horaDevolucao"
                  type="time"
                  required
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              FECHAR
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={error ? true : false}
            >
              DEVOLVER
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-5">
            <div className="form-group">
              <label htmlFor="unidadeId" className="form-label">
                Unidades
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={unidades}
                isDisabled={gestao.gestao ? false : true}
                value={unidades.find((x) => x.value === unidadeSelected)}
                placeholder=""
                onChange={async (e) => {
                  setUnidadeSelected(e.value);
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Regimes
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                placeholder=""
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Pesquisar
              </label>
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onMouseEnter={() => setWarningControl(true)}
                onMouseOut={() => setWarningControl(false)}
              />
            </div>
          </div>

          <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
            <div className="form-group" style={{ marginTop: "20px" }}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => handleSearchButton()}
              >
                <i className="material-icons-two-tone">search</i> Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            {isLoading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={newData ? newData : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={newData?.total}
                ref={tableRef}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default EmprestimosPage;
