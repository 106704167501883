import urlsConfiguracoes from "./urlsConfiguracoes";

const linksConfiguracoes = (administrador) => {
  return (
    {
      parametros: {
        id: 'parametros',
        text: 'PARÂMETROS',
        type: 'title',
        visible: administrador == 1,
        itens: {
          unidades: {
            id: 'unidades',
            text: 'UNIDADES',
            path: urlsConfiguracoes.unidades,
            icon: 'domain',
            visible: administrador == 1,
          },
          distritos: {
            id: 'distritos',
            text: 'DISTRITOS',
            path: urlsConfiguracoes.distritos,
            icon: 'domain',
            visible: administrador == 1,
          },
          escolaridade: {
            id: 'escolaridade',
            text: 'ESCOLARIDADES',
            path: urlsConfiguracoes.escolaridades,
            icon: 'school',
            visible: administrador == 1,
          },
          turnos: {
            id: 'turnos',
            text: 'TURNOS',
            path: urlsConfiguracoes.turnos,
            icon: '12mp',
            visible: administrador == 1,
          },
          disciplina: {
            id: 'disciplina',
            text: 'DISCIPLINAS',
            path: urlsConfiguracoes.disciplinas,
            icon: 'collections_bookmark',
            visible: administrador == 1,
          },
          responsavel: {
            id: 'responsavel',
            text: 'RESPONSÁVEIS',
            path: urlsConfiguracoes.responsaveis,
            icon: 'warning',
            visible: administrador == 1,
          },
          cargos: {
            id: 'vatgos',
            text: 'CARGOS',
            path: urlsConfiguracoes.cargos,
            icon: 'event_note',
            visible: administrador == 1,
          },
          nacionalidades: {
            id: 'vatgos',
            text: 'NACIONALIDADES',
            path: urlsConfiguracoes.nacionalidades,
            icon: 'event_note',
            visible: administrador == 1,
        },
        bnccFundamental: {
          id: 'bncc',
          text: 'BNCC FUNDAMENTAL',
          path: urlsConfiguracoes.bnncFundamental,
          icon: 'collections_bookmark',
          visible: administrador == 1,
      },
      webCam: {
        id: 'webCam',
        text: 'WEBCAM',
        path: urlsConfiguracoes.webcam,
        icon: 'collections_bookmark',
        visible: administrador == 1,
    },
    mapa: {
      id: 'mapa',
      text: 'MAPA',
      path: urlsConfiguracoes.mapas,
      icon: 'collections_bookmark',
      visible: administrador == 1,
  },
          
    }
},
    excluidos:{
      id: 'excluidos',
      text: 'EXCLUÍDOS',
      type: 'title',
      visible: administrador == 1,
      itens: {
        alunos: {
          id: 'alunos',
          text: 'ALUNOS',
          path: urlsConfiguracoes.alunosExcluidos,
          icon: 'groups',
          visible: administrador == 1,
        },
        servidores: {
          id: 'servidores',
          text: 'SERVIDORES',
          path: urlsConfiguracoes.servidoresExcluidos,
          icon: 'badge',
          visible: administrador == 1,
        },
      }
    }
    }
  )
};

export default linksConfiguracoes;