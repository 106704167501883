import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import PanelTypes from '../../store/ducks/panel';

const HeaderNotLogged = ({  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);
  const [openPicker, setOpenPicker] = useState(false);
  const [alunosFiltro, setAlunosFiltro] = useState([]);
  const [selectedAluno, setSelectedAluno] = useState(null);
  const dispatch = useDispatch();
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    const storedAluno = JSON.parse(localStorage.getItem('aluno'));
    setSelectedAluno(storedAluno);

    const alunosData = JSON.parse(localStorage.getItem('alunos'));

    if (alunosData !== null && alunosData.length > 1) {
      setShowPicker(true);
    }
    if (alunosData) {
      const unidades = alunosData.map(aluno => ({
        value: aluno.id,
        label: aluno.nome,
        aluno
      }));
      setAlunosFiltro(unidades);
    }
  }, []);

  return (
    <div className="app-header">
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="container-fluid d-flex align-items-center">
          <a
            id="titleUnidade"
            className="nav-link hide-sidebar-toggle-button d-flex align-items-center text-black"
            onClick={() => dispatch({ type: PanelTypes.OPEN_MENU, payload: { isMenuOpen: !isMenuOpen } })}
            style={{ color: 'black' }}
          >
            <i className="material-icons text-black" style={{ color: 'black' }}>menu_open</i>
            <span style={{ color: 'black' }}>{selectedAluno ? selectedAluno.nome : 'Selecione um aluno'}</span>
          </a>
          <div className="d-flex ms-auto">
            <ul className="navbar-nav d-flex align-items-center">
              {showPicker && !openPicker ? (
                <li className="nav-item">
                  <a
                    className="nav-link text-dark"
                    onClick={() => setOpenPicker(!openPicker)}
                  >
                    <i className="material-icons">edit</i> SELECIONAR ALUNO
                  </a>
                </li>
              ) : showPicker ? (
                <li className="nav-item">
                  {openPicker && (
                    <div style={{ width: '350px', marginTop: '20px' }}>
                      <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        options={alunosFiltro}
                        value={alunosFiltro.find(
                          x => x.value === selectedAluno?.id
                        )}
                        onChange={e => {
                          const selected = alunosFiltro.find(x => x.value === e.value).aluno;
                          setSelectedAluno(selected);
                          localStorage.setItem('aluno', JSON.stringify(selected));
                          setOpenPicker(false);
                          window.location.reload();
                        }}
                      />
                    </div>
                  )}
                </li>
              ) : null}
              <li className="nav-item hidden-on-mobile">
                <a className="nav-link text-dark" href="/">
                  <i
                    className="material-icons"
                    style={{ paddingBottom: '2px', marginRight: '2px' }}
                  >
                    logout
                  </i>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderNotLogged;
