import React, { useState, useEffect, useRef } from "react";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb";
import Frente from "./frente";
import Verso from "./verso";
import { getAluno } from "../../../services/alunos";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";

const AlunosCarteirinhaImpressaoPage = (gestao) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const [activeTab, setActiveTab] = useState("front");
  const [aluno, setAluno] = useState({});
  const printRef = useRef(null);

  const { data, loadingAluno } = useQuery(
    "getAluno",
    () => state.id !== undefined && getAluno(state.id),
    {
      retry: false,
      enabled: true,
      onSuccess: (response) => {
        debugger
        setAluno({
          ...response,
          nome: state.aluno.nome,
          numeroCarteiraNascimento: state.aluno.numeroCarteiraNascimento,
          cpf: state.aluno.cpf,
          rg: state.aluno.rg,
          endereco: state.aluno.endereco,
          telefone: state.aluno.telefone,
          unidade: state.aluno.unidade,
          escolaridade: state.aluno.escolaridade,
          turno: state.aluno.turno,
          turma: state.aluno.turma,
          fotoPerfil: state.aluno.fotoPerfil,
          transporte: {
            desembarqueNome: state.aluno.transporte.desembarqueNome,
            desembarqueParentesco: state.aluno.transporte.desembarqueParentesco,
            desembarqueNome2: state.aluno.transporte.desembarqueNome2,
            desembarqueParentesco2:
              state.aluno.transporte.desembarqueParentesco2,
            emergenciaNome: state.aluno.transporte.emergenciaNome,
            emergenciaTelefone: state.aluno.transporte.emergenciaTelefone,
            emergenciaParentesco: state.aluno.transporte.emergenciaParentesco,
            possuiAlergiaMedicamentos:
              state.aluno.transporte.possuiAlergiaMedicamentos,
            medicamentos: state.aluno.transporte.medicamentos,
            tipoSanguineo: state.aluno.transporte.tipoSanguineo,
            
          },
        });
      },
    }
  );

  useEffect(() => {
    if (state.id !== undefined && data === undefined) {
      getAluno(state.id);
    }
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "CNH_PDF",
  });

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Carteirinha"}
        itens={[BasePath, "Alunos", "Documentos"]}
      />

      <div>
        <div
          ref={printRef}
          style={{
            display: "flex",
            height: "370px",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "10px",
            }}
          >
            <Frente data={aluno} />
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "10px",
            }}
          >
            <Verso data={aluno} />
          </div>
        </div>
        <button onClick={handlePrint} style={{ marginTop: "20px" }}>
          Exportar PDF
        </button>
      </div>
    </BaseComponent>
  );
};

export default AlunosCarteirinhaImpressaoPage;
