import urlsReconhecimentoFacil from "./urlsReconhecimentoFacil";
const linksReconhecimentoFacil = () => {
  return (
    {
      biblioteca: {
        id: 'reconhecimento-facil',
        text: 'RECONHECIMENTO FACIL',
        type: 'title',
        visible: true,
        itens: {
          dashboard: {
            id: 'dashboard',
            text: 'DASHBOARD',
            path: urlsReconhecimentoFacil.dashboard,
            icon: 'dashboard',
            visible: true,
          },
          alunos: {
            id: 'alunos',
            text: 'ALUNOS',
            path: urlsReconhecimentoFacil.alunos,
            icon: 'group',
            visible: true,
          },
          servidores: {
            id: 'servidores',
            text: 'SERVIDORES',
            path: urlsReconhecimentoFacil.servidores,
            icon: 'group_add',
            visible: true,
          },
          webCam:{
            id: 'webCam',
            text: 'WEBCAM',
            path: urlsReconhecimentoFacil.webcam,
            icon: 'camera',
            visible: true,
          },
          listagem:{
            id: 'listagem',
            text: 'LISTAGEM',
            path: urlsReconhecimentoFacil.listagem,
            icon: 'group',
            visible: true,
          }
         
         
        }
      },   
    }
  )
};

export default linksReconhecimentoFacil;