import Breadcrumb from "../../../../components/breadcrumb";
import BaseAdministrativo from "../../baseAdministrativo";
import styled from 'styled-components';
import api from "../../../../services";
import React, { useEffect, useState } from 'react'


import './../../style.css'

const CardBase = styled.div`
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;



const DashboardTransporte = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await api.get(`/estatistica/transporte`);
      setData(response.data.data)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Dashoboard Transporte'} itens={['Transporte']} />
      <div className="row">
        <div className="col-xl-3">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-gray">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Total de alunos</span>
                  <span className="widget-stats-amount">{data?.totalAlunos}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-success">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Total Alunos ativos</span>
                  <span className="widget-stats-amount">{data?.totalAlunosAtivos}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-danger">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Total alunos inativos</span>
                  <span className="widget-stats-amount">{data?.totalAlunosInativos}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-purple">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Total alunos PNE</span>
                  <span className="widget-stats-amount">{data?.totalAlunosPne}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-purple">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Total de veículos cadastrados</span>
                  <span className="widget-stats-amount">{data?.totalAlunosPne}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-purple">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Veículos ativos</span>
                  <span className="widget-stats-amount">{data?.totalAlunosPne}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card widget widget-stats">
            <div className="card-body">
              <div className="widget-stats-container d-flex">
                <div className="widget-stats-icon widget-stats-icon-purple">
                  <i className="material-icons-outlined">group</i>
                </div>
                <div className="widget-stats-content flex-fill">
                  <span className="widget-stats-title">Veículos inativo</span>
                  <span className="widget-stats-amount">{data?.totalAlunosPne}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )
};

export default DashboardTransporte;