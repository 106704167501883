import React from 'react';
import { Route } from 'react-router-dom';
import urlsAdministrativo from '../../pages/administrativo/urlsAdministrativo';
import HomeAdministrativo from '../../pages/administrativo/homeAdministrativo';
import ControleFornecedores from '../../pages/administrativo/patrimonios/controleFornecedores';
import ControleFornecedoresForm from '../../pages/administrativo/patrimonios/controleFornecedores/cadastrar';
import ControleContratos from '../../pages/administrativo/patrimonios/controleContratos';
import ContratosForm from '../../pages/administrativo/patrimonios/controleContratos/cadastrar';
import CategoriaProdutosTable from '../../pages/administrativo/patrimonios/categoriaProdutos';
import CategoriaProdutosForm from '../../pages/administrativo/patrimonios/categoriaProdutos/cadastrar';
import ControleProdutosTable from '../../pages/administrativo/patrimonios/controleProdutos';
import ProdutosForm from '../../pages/administrativo/patrimonios/controleProdutos/cadastrar';
import ControleEstoque from '../../pages/administrativo/patrimonios/controleEstoque';
import ControleEstoqueForm from '../../pages/administrativo/patrimonios/controleEstoque/cadastrar';
import DashboardTransporte from '../../pages/administrativo/transporte/dashboard';
import TransporteAlunos from '../../pages/administrativo/transporte/alunos';
import TransporteFormularioPage from '../../pages/administrativo/transporte/formulario';
import AlunosPage from '../../pages/alunos';

const defaultPermisson = false;

const AdministrativoRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsAdministrativo.dashboard} element={ <HomeAdministrativo />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedores} element={ <ControleFornecedores />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedoresCadastrar} element={ <ControleFornecedoresForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedoresCadastrarId} element={ <ControleFornecedoresForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratos} element={ <ControleContratos />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratosCadastrar} element={ <ContratosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratosCadastrarId} element={ <ContratosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutos} element={ <CategoriaProdutosTable />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutosCadastrar} element={ <CategoriaProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutosCadastrarId} element={ <CategoriaProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutos} element={ <ControleProdutosTable />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutosCadastrar} element={ <ProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutosCadastrarId} element={ <ProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoque} element={ <ControleEstoque />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoqueCadastrar} element={ <ControleEstoqueForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoqueCadastrarId} element={ <ControleEstoqueForm />}/>
        <Route exact path={urlsAdministrativo.transporteDashboard} element={ <DashboardTransporte />}/>
        <Route exact path={urlsAdministrativo.transporteAlunos} element={ <AlunosPage gestao={false} administrativo={true} />}/>
        <Route exact path={urlsAdministrativo.transporteFormulario} element={ <TransporteFormularioPage />}/>
    </React.Fragment>
);

export default AdministrativoRoutes;
