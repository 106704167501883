import urlsPreMatricula from "./urlsPreMatricula";

const linksPreMatricula = {

  processo_seletivo: {
    id: 'processo_seletivo',
    text: 'Pré Matrícula 2024',
    type: 'title',
    visible: true,
    itens: {
      dashboard: {
        id: 'dashboard',
        text: 'DASHBOARD',
        path: urlsPreMatricula.dashboard,
        icon: 'dashboard',
        visible: true,
      },
      inscricoes: {
        id: 'inscricoes',
        text: 'INSCRIÇÕES',
        path: urlsPreMatricula.inscricoes,
        icon: 'inbox',
        visible: true,
      },
      pesquisa: {
        id: 'pesquisa',
        text: 'PESQUISA',
        path: urlsPreMatricula.pesquisa,
        icon: 'search',
        visible: true,
      },
    }
  },
  inscritos: {
    id: 'inscritos',
    text: 'Inscritos',
    type: 'title',
    visible: true,
    itens: {
      unidade: {
        id: 'unidade',
        text: 'POR UNIDADE',
        path: urlsPreMatricula.relatorioInscritosPorUnidade,
        icon: 'language',
        visible: true,
      },
      unidade_nominal: {
        id: 'unidade_nominal',
        text: 'POR UNIDADE - NOMINAL',
        path: urlsPreMatricula.relatorioInscritosPorUnidadeNominal,
        icon: 'language',
        visible: false,
      },
      escolaridade: {
        id: 'escolaridade',
        text: 'POR ESCOLARIDADE',
        path: urlsPreMatricula.relatorioInscritosPorEscolaridade,
        icon: 'language',
        visible: true,
      },
      bairro: {
        id: 'bairro',
        text: 'POR BAIRRO',
        path: urlsPreMatricula.relatorioInscritosPorBairro,
        icon: 'language',
        visible: true,
      },
      pne: {
        id: 'pne',
        text: 'POR PCD',
        path: urlsPreMatricula.relatorioInscritosPorPne,
        icon: 'language',
        visible: true,
      },
      transferencia_externa: {
        id: 'transferencia_externa',
        text: 'POR TRANS. EXTERNA',
        path: urlsPreMatricula.relatorioInscritosPorTransExterna,
        icon: 'language',
        visible: true,
      },
      transferencia_interna: {
        id: 'transferencia_interna',
        text: 'POR TRANS. INTERNA',
        path: urlsPreMatricula.relatorioInscritosPorTransInterna,
        icon: 'language',
        visible: true,
      },
      periodo_quantitativo: {
        id: 'periodo_quantitativo',
        text: 'PERÍODO - QUANTITATIVO',
        path: urlsPreMatricula.relatorioInscritosPorPeriodoQuantitativo,
        icon: 'language',
        visible: false,
      },
      novos_da_rede: {
        id: 'novos_da_rede',
        text: 'NOVOS DA REDE',
        path: urlsPreMatricula.relatorioInscritosPorNovoRede,
        icon: 'language',
        visible: true,
      },
      geral: {
        id: 'geral',
        text: 'GERAL',
        path: urlsPreMatricula.relatorioInscritosPorGeral,
        icon: 'language',
        visible: true,
      },
    }
  },
  convocados: {
    id: 'convocados',
    text: 'CONVOCAÇÃO',
    type: 'title',
    visible: true,
    itens: {
      periodo_quantitativo: {
        id: 'periodo_quantitativo',
        text: 'PERÍODO - QUANTITATIVO',
        path: urlsPreMatricula.relatorioConvocadosPorPeriodoQuantitativo,
        icon: 'language',
        visible: true,
      },
      periodo_nominal: {
        id: 'periodo_nominal',
        text: 'PERÍODO - NOMINAL',
        path: urlsPreMatricula.relatorioConvocadosPorPeriodoNominal,
        icon: 'language',
        visible: true,
      },
      escolaridade: {
        id: 'escolaridade',
        text: 'POR ESCOLARIDADE',
        path: urlsPreMatricula.relatorioConvocadosPorEscolaridade,
        icon: 'language',
        visible: true,
      },
    
    }
  },
  matriculados: {
    id: 'matriculados',
    text: 'MATRICULADOS',
    type: 'title',
    visible: true,
    itens: {
      unidade: {
        id: 'unidade',
        text: 'POR UNIDADE',
        path: urlsPreMatricula.relatorioMatriculadosPorUnidade,
        icon: 'language',
        visible: true,
      },
      escolaridade: {
        id: 'escolaridade',
        text: 'POR ESCOLARIDADE',
        path: urlsPreMatricula.relatorioMatriculadosPorEscolaridade,
        icon: 'language',
        visible: true,
      },
      periodo_quantitativo: {
        id: 'periodo_quantitativo',
        text: 'PERÍODO - QUANTITATIVO',
        path: urlsPreMatricula.relatorioMatriculadosPorPeriodoQuantitativo,
        icon: 'language',
        visible: true,
      },
      periodo_nominal: {
        id: 'periodo_nominal',
        text: 'PERÍODO - NOMINAL',
        path: urlsPreMatricula.relatorioMatriculadosPorPeriodoNominal,
        icon: 'language',
        visible: true,
      },
      geral: {
        id: 'geral',
        text: 'GERAL',
        path: urlsPreMatricula.relatorioMatriculadosPorGeral,
        icon: 'language',
        visible: true,
      },
    }
  },
};

export default linksPreMatricula;