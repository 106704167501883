import Breadcrumb from "../../components/breadcrumb";
import BaseGestaoUnidades from "./baseAdministrativo";
import Spinner from '../../components/spinner';
import { useEffect, useState } from "react";
import api from '../../services'

const HomeAdministrativo = () => {
  const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await api.get(`/estatistica/administrativo`);
      setData(response.data.data)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Informações Gerais'} itens={['Gestão de Unidades', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outlined">category</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total de Produtos</span>
                        <span className="widget-stats-amount">{data?.produtos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">content_paste</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total de Solicitações</span>
                        <span className="widget-stats-amount">{data?.solicitacoes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">print</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Impressoras</span>
                        <span className="widget-stats-amount">{data?.impressoras}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">accessibility_new</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Matriculas PNE</span>
                        <span className="widget-stats-amount">{data.matriculasPne}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-purple">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total de Matrículas</span>
                        <span className="widget-stats-amount">{data.totalMatricular}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-dark">
                        <i className="material-icons-outlined">calendar_month</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Dias Uteis</span>
                        <span className="widget-stats-amount">{data.diasUteis}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-purple">
                        <i className="material-icons-outlined">group</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total de Fornecedores</span>
                        <span className="widget-stats-amount">{data?.fornecedores}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

      </div>
          </>
        )}
    </BaseGestaoUnidades>
  )
};

export default HomeAdministrativo;