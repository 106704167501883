const baseUrl = '/gestao-unidades';

const urlsGestaoUnidades = {
    dashboard: baseUrl,
    alunos: `${baseUrl}/alunos`,
    matriculas: `${baseUrl}/matriculas`,
    matriculaEnturmarId: `${baseUrl}/matriculas/enturmar/:id`,
    matriculaEnturmar: `${baseUrl}/matriculas/enturmar/`,
    matriculasIndeferir: `${baseUrl}/matriculas/indeferir`,
    alunosAdicionar: `${baseUrl}/alunos/adicionar`,
    alunosEditar: `${baseUrl}/alunos/editar/`,
    alunosEditarId: `${baseUrl}/alunos/editar/:id`,
    alunosEvadir: `${baseUrl}/alunos/evadir`,
    alunosAprovacao: `${baseUrl}/alunos/aprovacao`,
    alunosAprovacaoEja: `${baseUrl}/alunos/aprovacao-eja`,
    bnccFundamental: `${baseUrl}/bncc-fundamental`,
    diarioClasse: `${baseUrl}/diario-classe`,
    diarioClasseAdicionar: `${baseUrl}/diario-classe/adicionar/`,
    diarioClasseAdicionarId: `${baseUrl}/diario-classe/adicionar/:id`,
    diarioClasseAvaliacoes: `${baseUrl}/diario-classe/avaliacoes/`,
    diarioClasseAvaliacoesId: `${baseUrl}/diario-classe/avaliacoes/:id`,
    diarioClasseAvaliacoesAlunos: `${baseUrl}/diario-classe/avaliacoes/alunos/`,
    diarioClasseAvaliacoesAlunosId: `${baseUrl}/diario-classe/avaliacoes/alunos/:id`,
    turmas: `${baseUrl}/turmas`,
    turmasAdicionar: `${baseUrl}/turmas/adicionar`,
    turmasEditar: `${baseUrl}/turmas/editar/`,
    turmasEditarId: `${baseUrl}/turmas/editar/:id`,
    turmasAlunos: `${baseUrl}/turmas/alunos/`,
    turmasAlunosId: `${baseUrl}/turmas/alunos/:id`,
    servidores: `${baseUrl}/servidores`,
    servidoresAdicionar: `${baseUrl}/servidores/adicionar`,
    servidoresEditar: `${baseUrl}/servidores/editar/`,
    servidoresEditarId: `${baseUrl}/servidores/editar/:id`,
    transferenciasRecebidas: `${baseUrl}/transferencias/recebidas`,
    transferenciasRecebidasAprovacao: `${baseUrl}/transferencias/recebidas/aprovacao`,
    transferenciasRecebidasCancelamento: `${baseUrl}/transferencias/recebidas/cancelamento`,
    transferenciasEnviadas: `${baseUrl}/transferencias/enviadas`,
    quadroHorario: `${baseUrl}/quadro-horario`,
    quadroHorarioCadastrar: `${baseUrl}/quadro-horario/cadastrar/`,
    quadroHorarioCadastrarId: `${baseUrl}/quadro-horario/cadastrar/:id`,
    quadroHorarioAlocarDisciplina: `${baseUrl}/quadro-horario/alocar-disciplina/`,
    quadroHorarioAlocarDisciplinaId: `${baseUrl}/quadro-horario/alocar-disciplina/:id`,
    quadroHorarioEditarDisplina: `${baseUrl}/quadro-horario/editar-disciplina/`,
    quadroHorarioEditarDisplinaId: `${baseUrl}/quadro-horario/editar-disciplina/:id`,
    quadroHorarioImpressao: `${baseUrl}/quadro-horario/imprimir`,
    faltas: `${baseUrl}/faltas`,
    faltasAdicionar: `${baseUrl}/faltas/adicionar/`,
    faltasAdicionarId: `${baseUrl}/faltas/adicionar/:id`,
    calendario: `${baseUrl}/calendario`,
    fichaAvaliacao: `${baseUrl}/ficha-avaliacao`,
    fichaAvaliacaoLancamento: `${baseUrl}/ficha-avaliacao/lancamento`,
    fichaAvaliacaoImpressao: `${baseUrl}/ficha-avaliacao/impressao`,
    fichaAvaliacao: `${baseUrl}/ficha-avaliacao`,
    fichaAvaliacaoVisualizacao: `${baseUrl}/ficha-avaliacao`,
    fichaAvaliacaoLancamento: `${baseUrl}/ficha-avaliacao/lancamento`,
    fichaAvaliacaoImpressao: `${baseUrl}/ficha-avaliacao/impressao`,
    fichaAvaliacaoInfantil: `${baseUrl}/ficha-avaliacao/infantil`,
    fichaAvaliacaoInfantilImpressao: `${baseUrl}/ficha-avaliacao/infantil/impressao`,
    fichaAvaliacaoPrimeiroAno: `${baseUrl}/ficha-avaliacao/primeiro-ano`,
    fichaAvaliacaoPrimeiroAnoImpressao: `${baseUrl}/ficha-avaliacao/primeiro-ano/impressao`,
    fichaAvaliacaoSegundoAno: `${baseUrl}/ficha-avaliacao/segundo-ano`,
    fichaAvaliacaoSegundoAnoImpressao: `${baseUrl}/ficha-avaliacao/segundo-ano/impressao`,
    fichaAvaliacaoTerceiroNonoAno: `${baseUrl}/ficha-avaliacao/terceiro-nono-ano`,
    fichaAvaliacaoTerceiroNonoAnoImpressao: `${baseUrl}/ficha-avaliacao/terceiro-nono-ano/impressao`,
    familiaConectadaAvisos: `${baseUrl}/familia-conectada/avisos`,
    familiaConectadaAvisosAdicionar: `${baseUrl}/familia-conectada/avisos/adicionar`,
    familiaConectadaAtendimento: `${baseUrl}/familia-conectada/atendimento`,
    riapCreche: `${baseUrl}/riap/creche-pre`,
    riapInfantil: `${baseUrl}/riap/infantil`,
    riapFundamental: `${baseUrl}/riap/fundamental`,
    riapFundamentalImpressao: `${baseUrl}/riap/fundamental/impressao`,
    riapEja: `${baseUrl}/riap/eja`,
    riapEjaImpressao: `${baseUrl}/riap/eja/impressao`,
    alunoCadastrarFoto: `${baseUrl}/aluno/cadastrar/foto`,
    servidorCadastrarFoto: `${baseUrl}/servidor/cadastrar/foto`,
    alunosCarteirinha: `${baseUrl}/alunos/carteirinha`,
    alunosCarteirinhaImpressao: `${baseUrl}/alunos/carteirinha/impressao`,
    fichaAvaliacaoSegundoAnoEja: `${baseUrl}/ficha-avaliacao/segundo-ano-eja`,
    fichaAvaliacaoSegundoAnoEjaImpressao: `${baseUrl}/ficha-avaliacao/segundo-ano-eja/impressao`,
    fichaAvaliacaoTerceiroAnoEja: `${baseUrl}/ficha-avaliacao/terceiro-ano-eja`,
    fichaAvaliacaoTerceiroAnoEjaImpressao: `${baseUrl}/ficha-avaliacao/terceiro-ano-eja/impressao`,
    fichaAvaliacaoPrimeiroAnoEja: `${baseUrl}/ficha-avaliacao/primeiro-ano-eja`,
    fichaAvaliacaoPrimeiroAnoEjaImpressao: `${baseUrl}/ficha-avaliacao/primeiro-ano-eja/impressao`,


}

export default urlsGestaoUnidades;